import { useSelector } from "react-redux";
import "./topBar.css";
import { Icons } from "utils/iconPath";
import NotificationModal from "components/notification/notificationModal/notificationModal";
import { useEffect, useState } from "react";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import useWindowDimensions from "utils/useWindowDimensions";
import { useTranslation } from "react-i18next";

const TopBar = ({ onClick }) => {
  // hooks
  const { width } = useWindowDimensions();
  const { t } = useTranslation("topBar");

  // states
  const [open, setOpen] = useState();
  const [translationModalOpen, setTranslationModalOpen] = useState(false);

  const supplierProfileRes = useSelector(
    (state) => state.supplierProfile.supplierProfileRes
  );

  // functions
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const currentTime = new Date().getHours();
  let greeting = t("good_evening");

  if (currentTime >= 0 && currentTime < 12) {
    greeting = t("good_morning");
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = t("good_afternoon");
  }

  return (
    <>
      <div className="top_bar_container" data-aos="fade-down" id="topBar">
        <div className="user_detail_container">
          <div className="avatar_container">
            <div className="avatar_img">
              <img
                src={supplierProfileRes?.logo ?? Icons.AVATAR}
                alt="avatar"
              />
            </div>
          </div>
          <div>
            <p className="heading3" style={{ fontFamily: "AgeoBold" }}>
              {greeting}
            </p>
            <p className="bodyText">
              {supplierProfileRes?.contact_person_name ?? "John Doe"}
            </p>
          </div>
        </div>
        <div className="iconsContainer" style={{ display: "flex" }}>
          <div className="notification_container">
            <div
              className={"notification_icon_container"}
              onClick={() => {
                setOpen(!open);
              }}
            >
              <img
                src={Icons.NOTIFICATION}
                alt="notification"
                className="notification_icon"
                style={{}}
              />
            </div>
            <div className="topBarIconContainer" onClick={onClick}>
              <img src={Icons.H_MENU} alt="menu" className="topBarIcon" />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={open}
        onClose={toggleDrawer}
        direction="right"
        style={{
          width: width <= 768 ? "100vw" : "85rem",
        }}
        duration={1000}
        overlayOpacity={0.25}
      >
        <NotificationModal isOpen={open} setIsOpen={setOpen} />
      </Drawer>
    </>
  );
};

export default TopBar;
