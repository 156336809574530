export const USER_TOKEN = "token";
export const IS_KEEP_ME_LOGGED_IN = "isKeepMeLoggedIn";

export const SIDEBAR_WIDTH = "40rem";
export const CURRENCY = "LKR";

export const LANGUAGE_TYPE = {
  en: "English",
  ar: "Arabic",
};

export const roles = {
  is_service_provider: "is_service_provider",
  is_staff: "is_staff",
};

export const LANGUAGE_TYPE_OPTIONS = [
  { display: LANGUAGE_TYPE.en, key: "en" },
  { display: LANGUAGE_TYPE.ar, key: "ar" },
];

export const SETTING_TYPE = {
  myAccount: "my-account",
  myOrders: "my-orders",
  savedAddress: "saved-address",
  savedCards: "saved-cards",
  passwordSecurity: "password-security",
  platformSettings: "platform-settings",
};

export const SETTING_OPTIONS = [
  { display: SETTING_TYPE.myAccount, key: SETTING_TYPE.myAccount },
  { display: SETTING_TYPE.myOrders, key: SETTING_TYPE.myOrders },
  { display: SETTING_TYPE.savedAddress, key: SETTING_TYPE.savedAddress },
  { display: SETTING_TYPE.savedCards, key: SETTING_TYPE.savedCards },
  {
    display: SETTING_TYPE.passwordSecurity,
    key: SETTING_TYPE.passwordSecurity,
  },
  {
    display: SETTING_TYPE.platformSettings,
    key: SETTING_TYPE.platformSettings,
  },
];

export const GENDER_OPTIONS = [
  { display: "male", key: "male" },
  { display: "female", key: "female" },
  { display: "others", key: "others" },
];

//LISTING CONSTANTS
export const GET_LIST = "getList";
export const GET_PAGINATION_LIST = "getPaginationList";
export const PAGINATION_INITIAL_LIST_RES = {
  count: null,
  next: null,
  previous: null,
  results: [],
};

export const TIME_ARRAY = [
  { value: "00:00:00", displayName: "12:00 AM" },
  { value: "00:30:00", displayName: "12:30 AM" },
  { value: "01:00:00", displayName: "01:00 AM" },
  { value: "01:30:00", displayName: "01:30 AM" },
  { value: "02:00:00", displayName: "02:00 AM" },
  { value: "02:30:00", displayName: "02:30 AM" },
  { value: "03:00:00", displayName: "03:00 AM" },
  { value: "03:30:00", displayName: "03:30 AM" },
  { value: "04:00:00", displayName: "04:00 AM" },
  { value: "04:30:00", displayName: "04:30 AM" },
  { value: "05:00:00", displayName: "05:00 AM" },
  { value: "05:30:00", displayName: "05:30 AM" },
  { value: "06:00:00", displayName: "06:00 AM" },
  { value: "06:30:00", displayName: "06:30 AM" },
  { value: "07:00:00", displayName: "07:00 AM" },
  { value: "07:30:00", displayName: "07:30 AM" },
  { value: "08:00:00", displayName: "08:00 AM" },
  { value: "08:30:00", displayName: "08:30 AM" },
  { value: "09:00:00", displayName: "09:00 AM" },
  { value: "09:30:00", displayName: "09:30 AM" },
  { value: "10:00:00", displayName: "10:00 AM" },
  { value: "10:30:00", displayName: "10:30 AM" },
  { value: "11:00:00", displayName: "11:00 AM" },
  { value: "11:30:00", displayName: "11:30 AM" },
  { value: "12:00:00", displayName: "12:00 PM" },
  { value: "12:30:00", displayName: "12:30 PM" },
  { value: "13:00:00", displayName: "01:00 PM" },
  { value: "13:30:00", displayName: "01:30 PM" },
  { value: "14:00:00", displayName: "02:00 PM" },
  { value: "14:30:00", displayName: "02:30 PM" },
  { value: "15:00:00", displayName: "03:00 PM" },
  { value: "15:30:00", displayName: "03:30 PM" },
  { value: "16:00:00", displayName: "04:00 PM" },
  { value: "16:30:00", displayName: "04:30 PM" },
  { value: "17:00:00", displayName: "05:00 PM" },
  { value: "17:30:00", displayName: "05:30 PM" },
  { value: "18:00:00", displayName: "06:00 PM" },
  { value: "18:30:00", displayName: "06:30 PM" },
  { value: "19:00:00", displayName: "07:00 PM" },
  { value: "19:30:00", displayName: "07:30 PM" },
  { value: "20:00:00", displayName: "08:00 PM" },
  { value: "20:30:00", displayName: "08:30 PM" },
  { value: "21:00:00", displayName: "09:00 PM" },
  { value: "21:30:00", displayName: "09:30 PM" },
  { value: "22:00:00", displayName: "10:00 PM" },
  { value: "22:30:00", displayName: "10:30 PM" },
  { value: "23:00:00", displayName: "11:00 PM" },
  { value: "23:30:00", displayName: "11:30 PM" },
];

export const ADDITIONAL_PAYMENT_STATUS = {
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  PENDING: "pending",
};

export const HOME_BOOKING_CHART_FILTER_TYPES = [
  { value: "today", displayName: "Today" },
  { value: "yesterday", displayName: "Yesterday" },
  { value: "this_week", displayName: "This_week" },
  { value: "last_week", displayName: "Last_week" },
  { value: "this_month", displayName: "This_month" },
  { value: "last_month", displayName: "Last_month" },
  { value: "this_year", displayName: "This_year" },
  { value: "last_year", displayName: "Last_year" },
  { value: "life_time", displayName: "Life_time" },
];

export const HOME_REVENUE_CHART_FILTER_TYPES = [
  { value: "this_month", displayName: "This_month" },
  { value: "last_month", displayName: "Last_month" },
  { value: "this_year", displayName: "This_year" },
  { value: "last_year", displayName: "Last_year" },
  { value: "life_time", displayName: "Life_time" },
];

export const TRANSLATION = [
  { value: "en", displayName: "English International" },
  { value: "tam", displayName: "Tamil" },
  { value: "sin", displayName: "Sinhala " },
];

export const SERVICE_FIELD_TYPE = {
  INTEGER: "IntegerField",
  TEXT: "TextField",
  CHARACTER: "CharField",
  BOOLEAN: "BooleanField",
  OPTIONS: "OptionField",
  DROPDOWN: "DropDownField",
  PRICE_COMBINATION: "PriceCombinationField",
};

export const NOTIFICATION_TYPE = {
  SERVICE: "services",
  SYSTEM: "system",
  BOOKING: "booking",
  NEW_BOOKING: "new_booking",
  PROFILE: "profile",
  PROMO: "promo",
  WALLET: "wallet",
  RECURRING_BOOKING: "recurring_booking",
};
