const { Icons } = require("utils/iconPath");

const SideBarLinks = [
  {
    name: "Home",
    label: "home",
    link: "/home",
    icon: Icons.HOME,
    activeIcon: Icons.HOME_ACTIVE,
  },
  {
    name: "My Calendar",
    label: "my_calendar",
    link: "/calendar",
    icon: Icons.CALENDAR_ACTIVE,
    activeIcon: Icons.CALENDAR_SIDE_ACTIVE,
  },
  {
    name: "Bookings",
    label: "bookings",
    link: "/bookings",
    icon: Icons.BOOKING,
    activeIcon: Icons.BOOKING_ACTIVE,
  },
  {
    name: "Staff",
    label: "staff",
    link: "/staff",
    icon: Icons.STAFF,
    activeIcon: Icons.STAFF_ACTIVE,
  },
  {
    name: "Profile",
    label: "profile",
    link: "/profile",
    icon: Icons.PROFILE,
    activeIcon: Icons.PROFILE_ACTIVE,
  },
];

export default SideBarLinks;
