import { Outlet } from "react-router-dom";
import TopBar from "./topBar";
import "./style.css";
import { closeDrawer, openDrawer } from "redux/slice/drawerSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SideBar from "./sideBar/sideBar";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import useWindowDimensions from "utils/useWindowDimensions";
import { Suspense, useEffect } from "react";
import Loading from "common/Loading";

const Layout = () => {
  const { width } = useWindowDimensions();
  const { isOpen } = useSelector(
    (state) => ({
      isOpen: state.drawer.isOpen,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const openSideBar = () => {
    dispatch(openDrawer());
  };

  const toggleDrawer = () => {
    dispatch(closeDrawer());
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dispatch(closeDrawer());
      }
    };

    if (isOpen) {
      document.body.classList.add("modal-open");
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  // Logic to get actual viewport Height of mobile and tablet mode (for credit section)
  // 1.) First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // 2.) Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    // 3.)  We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  const CustomLoading = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          // background: "rgba(0,0,0,.15)",
        }}
      >
        <Loading />
      </div>
    );
  };

  return (
    <>
      <div>
        <div>
          {width <= 768 ? (
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction="left"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                height: "100vh",
                position: "fixed",
              }}
            >
              <SideBar />
            </Drawer>
          ) : (
            <SideBar />
          )}
        </div>
        <div>
          <div className="layoutSubContainer">
            <TopBar onClick={openSideBar} />
            <div
              className="layoutSubMainContainer"
              data-aos="fade"
              data-aos-delay="400"
            >
              <Suspense fallback={<CustomLoading />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
