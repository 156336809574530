import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { NEW_BOOKING } from "redux/services/apiTypes";

const initialState = {
  newBookingLoading: false,
  newBookingSuccess: false,
  newBookingRes: null,
  newBookingErr: null,
};

export const getNewBookings = createAsyncThunk(
  "bookings/new",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${data.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreNewBookings = createAsyncThunk(
  "bookings/moreNew",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${data.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const newBookingSlice = createSlice({
  name: "newBooking",
  initialState,
  reducers: {
    clearNewBooking: (state, action) => {
      state.newBookingLoading = false;
      state.newBookingSuccess = false;
      state.newBookingRes = null;
      state.newBookingErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewBookings.pending, (state, action) => {
        state.newBookingLoading = true;
        state.newBookingSuccess = false;
      })
      .addCase(getNewBookings.fulfilled, (state, action) => {
        state.newBookingLoading = false;
        state.newBookingSuccess = true;
        state.newBookingRes = action.payload;
      })
      .addCase(getNewBookings.rejected, (state, action) => {
        state.newBookingLoading = false;
        state.newBookingSuccess = true;
        state.newBookingErr = action.payload;
      })
      .addCase(getMoreNewBookings.pending, (state, action) => {
        state.newBookingLoading = false;
        state.newBookingSuccess = false;
      })
      .addCase(getMoreNewBookings.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...state.newBookingRes.results, ...action.payload.results],
        };
        state.newBookingLoading = false;
        state.newBookingSuccess = true;
        state.newBookingRes = res;
      })
      .addCase(getMoreNewBookings.rejected, (state, action) => {
        state.newBookingLoading = false;
        state.newBookingSuccess = true;
        state.newBookingErr = action.payload;
      });
  },
});

export const { clearNewBooking } = newBookingSlice.actions;

export default newBookingSlice.reducer;
