import "./sideBar.css";
import { Icons } from "utils/iconPath";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import SideBarLinks from "../SideBarLinks";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "redux/slice/drawerSlice";
import { makeUserLogout } from "utils";

// import SecondaryButton from "common/Button/SecondaryButton";
import { useEffect, useState } from "react";
import Modal from "common/Modal";
import { SecondaryButton, TertiaryButton } from "common/Button";

const SideBar = ({ style }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("sideBar");

  let WindowLocation = window.location.pathname.split("/");

  // state
  const [open, setOpen] = useState(false);

  const modalCloseHandle = () => {
    setOpen(!open);
  };

  const toggleDrawer = () => {
    dispatch(closeDrawer());
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    if (open) {
      document.body.classList.add("modal-open");
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  // Logic to get actual viewport Height of mobile and tablet mode (for credit section)
  // 1.) First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // 2.) Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    // 3.)  We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <>
      <div
        className="sideBarContainer"
        style={{
          ...style,
        }}
        data-aos="fade-right"
      >
        <div>
          {/* <div className="sideBarCloseButtonContainer">
              <img
                src={Icons.CLOSE}
                alt="menu"
                className="sideBarMenuIcon"
                onClick={sideBarOpen}
              />
            </div> */}
          <div className="sideBar_logo">
            <img src={Icons.SIDEBAR_APP_LOGO} alt="" />
          </div>
          <div className="sideBar_Gradient_Container">
            <h6 className="bodyText gradient_Text">{t("partners_panel")}</h6>
          </div>
          <div className="sideBar_menus_container">
            {SideBarLinks.map((item, key) => {
              let locationName = item.link.split("/");
              return (
                <Link
                  key={`sidebar_menu_${key}`}
                  to={item.link}
                  onClick={toggleDrawer}
                >
                  <div
                    className={`sideBar_menu ${
                      WindowLocation[1] === locationName[1]
                        ? "sideBar_menu_active"
                        : "sideBar_menu_inactive"
                    }`}
                  >
                    <div
                      className={`sidebar_menu_icon_container ${
                        WindowLocation[1] === locationName[1]
                          ? "sidebar_menu_icon_active"
                          : "sidebar_menu_icon_inactive"
                      }`}
                    >
                      <div className="sidebar_menu_icon">
                        <img
                          src={
                            WindowLocation[1] === locationName[1]
                              ? item.activeIcon
                              : item.icon
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <p
                      className={`bodyText sideBar_menu_text ${
                        WindowLocation[1] === locationName[1]
                          ? "sideBar_menu_text_active"
                          : "sideBar_menu_text_inactive"
                      }`}
                    >
                      {t(item.label)}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        {/* ----modal markup start*/}
        <Modal
          isOpen={open}
          onClose={modalCloseHandle}
          modalHeading={t("logout_confirmation")}
          children={
            <div className="modalChildrenContainer">
              <div className="heading3 modalChildrenHeading">
                {t("are_you_sure_you_want_to_log_out")}
              </div>
              <div className="modalChildrenButtonContainer">
                <TertiaryButton
                  title={t("cancel")}
                  onClick={modalCloseHandle}
                />
                <SecondaryButton
                  title={t("yes_logout")}
                  onClick={makeUserLogout}
                  style={{ backgroundColor: "#FF4D4F", color: "white" }}
                />
              </div>
            </div>
          }
        />

        {/* ----modal markup end */}

        <div
          className="sideBar_logout_menu"
          onClick={() => {
            modalCloseHandle();
          }}
        >
          <div className="sideBar_menu sideBar_menu_inactive">
            <div className="sidebar_menu_icon_container sidebar_menu_icon_inactive">
              <div className="sidebar_menu_icon">
                <img src={Icons.LOGOUT} alt="" />
              </div>
            </div>

            <p className="bodyText sideBar_menu_text sideBar_menu_text_inactive">
              {t("logout")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
