import { lazy } from "react";
const MyCalendar = lazy(() => import("components/calendar"));
const StaffAvailability = lazy(() =>
  import("components/staff/staffAvailability/StaffAvailability")
);
const Home = lazy(() => import("components/home"));
const Bookings = lazy(() => import("components/bookings"));
const Staff = lazy(() => import("components/staff"));
const Profile = lazy(() => import("components/profile"));
const AddStaff = lazy(() => import("components/staff/addStaff"));
// const NotificationDetail = lazy(() => import("components/notification"));
const AboutUs = lazy(() => import("components/profile/aboutUs/AboutUs"));
const Help = lazy(() => import("components/profile/help/Help"));
const FAQ = lazy(() => import("components/profile/help/FAQ/FAQ"));
const ManageAvailability = lazy(() =>
  import("components/profile/manageAvailability/ManageAvailability")
);
const Earning = lazy(() => import("components/profile/earning/Earning"));
const BookingDetails = lazy(() =>
  import("components/bookings/bookingDetails/BookingDetails")
);
const MyService = lazy(() => import("components/profile/myService/MyService"));
const ServiceDetail = lazy(() =>
  import("components/profile/myService/serviceDetail/ServiceDetail")
);
const Payback = lazy(() => import("components/profile/payback/Payback"));

const ScreenRoutes = [
  { path: "/home", name: "Home", element: <Home /> },
  { path: "/bookings", name: "Booking", element: <Bookings /> },
  { path: "/staff", name: "Staff", element: <Staff /> },
  { path: "/profile", name: "Profile", element: <Profile /> },
  { path: "/staff/addstaff", name: "AddStaff", element: <AddStaff /> },
  { path: "/staff/update", name: "staffUpdate", element: <AddStaff /> },
  { path: "/profile/about-us", name: "aboutUs", element: <AboutUs /> },
  { path: "/profile/help", name: "help", element: <Help /> },
  { path: "/profile/help/FAQ", name: "help", element: <FAQ /> },
  { path: "/profile/earning", name: "Earning", element: <Earning /> },
  {
    path: "/profile/availability",
    name: "help",
    element: <ManageAvailability />,
  },
  // {
  //   path: "/notification",
  //   name: "notification",
  //   element: <NotificationDetail />,
  // },
  {
    path: "/bookings/detail/:id",
    name: "bookingDetail",
    element: <BookingDetails />,
  },
  {
    path: "/profile/service/",
    name: "profileService",
    element: <MyService />,
  },
  {
    path: "/profile/service/details/:id",
    name: "serviceDetail",
    element: <ServiceDetail />,
  },
  {
    path: "/profile/payback",
    name: "serviceDetail",
    element: <Payback />,
  },
  {
    path: "/calendar",
    name: "calendar",
    element: <MyCalendar />,
  },
  {
    path: "/staff/availability/:id",
    name: "calendar",
    element: <StaffAvailability />,
  },
];

export default ScreenRoutes;
