import { register } from "swiper/element/bundle";
import "./firebase";
import AOS from "aos";
import "aos/dist/aos.css";
import { lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "components/layout";
import ScreenRoutes from "routes/screenRoutes";
import ProtectedRoutes from "routes/protectedRoutes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSupplierProfile } from "redux/slice/supplierProfileSlice/supplierProfileSlice";
import { Toaster } from "react-hot-toast";
import { USER_TOKEN } from "utils/constant";
import SplashScreen from "components/splashScreen";
const SignIn = lazy(() => import("components/authentication/SignIn"));
const Verification = lazy(() =>
  import("components/authentication/verification/Verification")
);

const App = () => {
  const token = localStorage.getItem(USER_TOKEN);
  register();

  const { isUserLoggedIn } = useSelector(
    (state) => ({
      isUserLoggedIn: state.auth.isUserLoggedIn,
    }),
    shallowEqual
  );

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
    // window.onload = (e) => {
    //   // Your code to run after the window has loaded
    //   console.log("loadingData", e);
    //   setLoading(false);
    // };
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getSupplierProfile());
    }
  }, [token]);

  useEffect(() => {
    AOS.init({
      disable: "fade-down",
      disableMutationObserver: false,
    });

    window.addEventListener("scroll", () => {
      AOS.refreshHard();
    });

    return () => {
      window.removeEventListener("scroll", () => {
        AOS.refreshHard();
      });
    };
  }, []);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />

              <Route
                path="/signin"
                element={
                  isUserLoggedIn ? <Navigate to="/home" replace /> : <SignIn />
                }
              />
              <Route
                path="/verification"
                element={
                  isUserLoggedIn ? (
                    <Navigate to="/home" replace />
                  ) : (
                    <Verification />
                  )
                }
              />
              <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/" name="Home" element={<Layout />}>
                  {ScreenRoutes.map((route, idx) => {
                    return (
                      <Route
                        key={`Layout_${idx}`}
                        path={route.path}
                        name={route.name}
                        element={route.element}
                      />
                    );
                  })}
                </Route>
              </Route>
            </Routes>
          </Router>
          <Toaster position="right" />
        </>
      )}
    </>
  );
};

export default App;
