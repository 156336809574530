import { configureStore } from "@reduxjs/toolkit";
import drawerSlice from "./slice/drawerSlice";
import authenticationSlice from "./slice/authenticationSlice/authenticationSlice";
import supplierProfileSlice from "./slice/supplierProfileSlice/supplierProfileSlice";
import staffListSlice from "./slice/staffListSlice/staffListSlice";
import newBookingSlice from "./slice/bookingSlice/newBookingSlice";
import onGoingBookingSlice from "./slice/bookingSlice/onGoingBookingSlice";
import historyBookingSlice from "./slice/bookingSlice/historyBookingSlice";
import notificationSlice from "./slice/notificationSlice/notificationSlice";
import FAQSlice from "./slice/FAQSlice/FAQSlice";
import manageAvailabilitySlice from "./slice/manageAvailabilitySlice/manageAvailabilitySlice";
import transactionSlice from "./slice/transactionSlice/transactionSlice";
import bookingDetailSlice from "./slice/bookingDetail/bookingDetailSlice";
import dashboardSlice from "./slice/dashboard/dashboardSlice";
import bookingCalendarSlice from "./slice/bookingCalendarSlice/bookingCalendarSlice";
import recurringBookingDatesSlice from "./slice/recurringBookingDatesSlice/recurringBookingDatesSlice";
import myServiceSlice from "./slice/myServicesSlice/myServiceSlice";
import ratingListSlice from "./slice/ratingListSlice/ratinglistSlice";
import serviceDetailSlice from "./slice/serviceDetailSlice/serviceDetailSlice";

const store = configureStore({
  reducer: {
    drawer: drawerSlice,
    newBooking: newBookingSlice,
    auth: authenticationSlice,
    supplierProfile: supplierProfileSlice,
    staffList: staffListSlice,
    onGoingBooking: onGoingBookingSlice,
    historyBooking: historyBookingSlice,
    notification: notificationSlice,
    FAQ: FAQSlice,
    manageAvailability: manageAvailabilitySlice,
    transaction: transactionSlice,
    bookingDetail: bookingDetailSlice,
    dashboard: dashboardSlice,
    bookingCalendar: bookingCalendarSlice,
    recurringDates: recurringBookingDatesSlice,
    myServices: myServiceSlice,
    ratingList: ratingListSlice,
    serviceDetail: serviceDetailSlice,
  },
});

export default store;
